import { ReactElement, useCallback } from 'react';

import { AxiosResult, AxiosResultDefaultError } from '../../../../api/request';
import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import { AuthenticationError } from '../../../GradeEntry/Loaders/CreateEvaluationGradeEntryBaseLoader';
import { getGradeReplacementPetitionsList as getPaginationBase } from '../../api/pendingPetitions';
import { ITEMS_PER_PAGE_DEFAULT, onSearch } from '../../types/common';
import { GradeReplacementPetitionsResponse } from '../../types/pendingPetitions';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  onSearch,
  GradeReplacementPetitionsResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function usePetitionsTableLoader() {
  const context = useFetch();

  return {
    error: context.error,
    loading: context.loading,
    data: context.data,
    query: context.query,
    updateQuery: context.updateQuery,
  };
}

export const PetitionsTableLoaderConsumer = FetchConsumer;

export default function PetitionsTableLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async (query: onSearch): Promise<
    AxiosResult<GradeReplacementPetitionsResponse, AxiosResultDefaultError>
  > => {
    return getPaginationBase({
      items: ITEMS_PER_PAGE_DEFAULT,
      page: query.page - 1,
      search: query?.searchParams?.search,
    });
  }, []);

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately
    >
      {children}
    </FetchProvider>
  );
}
