import { useCallback, useMemo, useState } from 'react';
import { Card } from 'reactstrap';

import { onSearch, SearchParams } from '../types/common';
import PetitionsTable from './parts/PetitionsTable';
import { usePetitionsTableLoader } from './parts/PetitionsTableLoader';

export default function PendingPetitions() {
  const { updateQuery } = usePetitionsTableLoader();

  const [filters, setFilters] = useState<SearchParams>({});

  const isSearch = useMemo(() => {
    return filters?.hasFilters ?? false;
  }, [filters]);

  const handleSearch = useCallback(
    async ({ searchParams, page = 1 }: onSearch) => {
      updateQuery({
        page: page,
        searchParams: searchParams,
      });
    },
    [updateQuery],
  );

  return (
    <Card className="mx-3 p-4">
      <div className="mb-5">
        {/* <SearchControls
          onSearch={handleSearch}
          setFilters={setFilters}
        /> */}
      </div>

      <PetitionsTable isSearch={isSearch} />
    </Card>
  );
}
