import request from '../../../api/request';
import { SearchParams } from '../types/common';
import { GradeReplacementPetitionsResponse } from '../types/pendingPetitions';

export const GRADE_REPLACEMENT_PETITIONS = {
  LIST: '/teacher-portal/grade-replacement-petitions',
};

export const getGradeReplacementPetitionsList = async (
  filter: { items: number; page: number } & SearchParams,
) => {
  const URL = GRADE_REPLACEMENT_PETITIONS.LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<GradeReplacementPetitionsResponse>(URL, { method, params });
};
