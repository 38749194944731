export type SearchParams = {
  search?: string;
  hasFilters?: boolean;
};

export type onSearch = {
  searchParams?: SearchParams;
  page: number;
};

export const ITEMS_PER_PAGE_DEFAULT = 10;
