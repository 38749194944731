import { Button, ColumnTable } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { TranslationsKeys } from '../../../../locales/translations';

export const useColumns = () => {
  const { t } = useTranslation(TranslationsKeys.PETITIONS);

  const columns: ColumnTable<any>[] = [
    {
      headerText: t(`table.columns.nameAndId`),
      columnName: 'studentName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
      cellFormat: ({ row }) => {
        return (
          <p>
            {row?.studentName}
            <br />
            {row?.studentRun && format(row.studentRun)}
          </p>
        );
      },
    },
    {
      headerText: t(`table.columns.typeName`),
      columnName: 'typeName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`table.columns.createdAt`),
      columnName: 'createdAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
      cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
    },
    {
      headerText: t(`table.columns.group`),
      columnName: 'campusName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
    },
    {
      headerText: t(`table.columns.sectionName`),
      columnName: 'sectionName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
    },
    {
      headerText: t(`table.columns.periodName`),
      columnName: 'periodName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
    },
    {
      headerText: t(`table.columns.grade`),
      columnName: 'grade',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      bold: true,
      width: '15%',
    },
    {
      headerText: t(`table.columns.action`),
      columnName: 'folio',
      width: '60px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: (options: CellFormatOptions) => {
        return (
          <div
            className="d-flex justify-content-center"
            style={{ gap: '10px' }}
          >
            <Button
              text={t(`table.actions.change`)}
              fullwidth
              size="sm"
              disabled
            />
          </div>
        );
      },
    },
  ];

  return columns;
};
